@import "../../../../App/variables.scss";

.BannerSection {
  padding-top: 120px;

  h1 {
    padding-top: 85px;
    font-family: $h_font_family;
    font-size: 81px;
    font-weight: bold;
    color: $dark_brown;
  }

  p {
    margin-bottom: 28px;
    margin-top: 22px;
  }

  a {
    display: block;
    &.anchor-link {
      float: right;
      margin-top: 50px;
    }

    svg {
      font-size: 26px;
      margin-top: -2px;
      margin-right: 5px;
    }
  }

  .cs_banner {
    position: relative;
    min-height: 660px;
    padding-top: 70px;

    img {
      position: absolute;
      left: 0px;
      animation-name: multiple-image-crossfade;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
      animation-duration: 16s;
      width: 515px;

      &:nth-of-type(1) {
        animation-delay: 12s;
      }

      &:nth-of-type(2) {
        animation-delay: 8s;
      }

      &:nth-of-type(3) {
        animation-delay: 4s;
      }

      &:nth-of-type(4) {
        animation-delay: 0;
      }

      &.bg {
        animation: none;
        z-index: 0;
        width: 670px;
        height: 520px;
        position: absolute;
        left: -74px;
        top: 95px;
      }
    }
  }

  @keyframes multiple-image-crossfade {
    0% {
      opacity: 1;
    }

    17% {
      opacity: 1;
    }

    25% {
      opacity: 0;
    }

    92% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}

@media screen and (max-width: $break-large) {
  .BannerSection {
    h1 {
      font-size: 4rem;
    }

    .Subscribe {
      .basic_btn {
        max-width: 270px;
        margin-top: 10px;
      }
    }

    // .cs_banner {
    //   min-height: 650px;

    //   img {
    //     left: 80px;
    //     width: auto;
    //     height: 600px;

    //     &.bg {
    //       width: auto;
    //       height: 700px;
    //     }
    //   }
    // }
  }
}

@media screen and (max-width: $break-small) {
  .BannerSection {
    padding-top: 120px;

    h1 {
      padding-top: 300px;
      font-size: 3rem;
    }

    .cs_banner {
      min-height: 400px;

      img {
        left: 0px;
        width: auto;
        height: 550px;

        &.bg {
          width: auto;
          height: 550px;
          left: 7px;
        }
      }
    }
  }
}
