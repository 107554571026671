@import "../../../../../../../App/variables.scss";

.BlogsItem {
  background: $white;
  border-radius: 6px;
  // min-height: 400px;

  .cs_imgwrap {
    height: 177px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    overflow: hidden;
    text-align: center;

    img {
      object-fit: cover;
      min-height: 100%;
      width: 100%;
    }
  }

  .cs_contentwrap {
    padding: 26px 30px 30px 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 195px;

    .cs_title {
      font-family: $h_font_family;
      font-size: 18px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.33;
      letter-spacing: normal;
      color: $dark_brown;
      margin-bottom: 2px;
    }

    .cs_text {
      font-family: $t_font_family;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: $lighter_brown;
      margin-bottom: 10px;
    }

    .cs_date {
      font-family: $t_font_family;
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: $lighter_brown;
    }
  }
}
