@import "../../../../App/variables.scss";

.Questions {
  height: auto;
  position: relative;
  padding-bottom: 120px;
  margin-top: -50px;

  h2 {
    margin-bottom: 60px;
  }

  .toggle-buttons {
    text-align: center;
    margin-top: 60px;
    margin-bottom: 50px;
  }

  img {
    max-height: 570px;
  }
}

@media screen and (max-width: $break-xlarge) {
  .Questions {
    margin-top: -100px;
  }
}

@media screen and (max-width: $break-large) {
  .Questions {
    margin-top: -150px;
  }
}

@media screen and (max-width: $break-small) {
  .Questions {
    margin-top: -200px;
    padding-bottom: 0px;
  }
}
