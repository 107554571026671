@import '../../../../../../../App/variables.scss';

.ReadItem {
    background: $white;
    border-radius: 6px;


    .cs_imgwrap {
        height: 180px;
        width: 180px;
        border-top-left-radius: 4px;
        overflow: hidden;
        text-align: center;
        display: inline-block;
        vertical-align: top;

        img {
            object-fit: cover;
            min-height: 100%;
            width: 100%;
        }
    }

    .cs_contentwrap {
        padding: 0px 20px 15px 20px;
        width: calc(100% - 180px);
        max-height: 180px;
        display: inline-block;

        .cs_title {
            font-family: $h_font_family;
            font-size: 18px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.33;
            letter-spacing: normal;
            color: $dark_brown;
            margin-bottom: 2px;
        }

        .cs_text {
            font-family: $t_font_family;
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            height: 111px;
            overflow: hidden;
            text-overflow: ellipsis;
            font-stretch: normal;
            line-height: 1.5;
            letter-spacing: normal;
            color: $lighter_brown;
            margin-bottom: 10px;
        }

        .cs_date {
            font-family: $t_font_family;
            font-size: 12px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.5;
            letter-spacing: normal;
            color: $lighter_brown;
        }

    }

}





@media screen and (max-width: $break-small) {
    .ReadItem {

        .cs_imgwrap {
            display: block;
            width: 100%;
        }

        .cs_contentwrap {
            padding: 20px 0px 30px 0px;
            display: block;
            width:100%;
            max-height:inherit;

            .cs_text {
                height: auto;
            }
        }

    }

}