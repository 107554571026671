.BannerSection {
  padding-top: 120px;
}
.BannerSection h1 {
  padding-top: 85px;
  font-family: "Cabin", sans-serif;
  font-size: 81px;
  font-weight: bold;
  color: #413d37;
}
.BannerSection p {
  margin-bottom: 28px;
  margin-top: 22px;
}
.BannerSection a {
  display: block;
}
.BannerSection a.anchor-link {
  float: right;
  margin-top: 50px;
}
.BannerSection a svg {
  font-size: 26px;
  margin-top: -2px;
  margin-right: 5px;
}
.BannerSection .cs_banner {
  position: relative;
  min-height: 660px;
  padding-top: 70px;
}
.BannerSection .cs_banner img {
  position: absolute;
  left: 0px;
  animation-name: multiple-image-crossfade;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 16s;
  width: 515px;
}
.BannerSection .cs_banner img:nth-of-type(1) {
  animation-delay: 12s;
}
.BannerSection .cs_banner img:nth-of-type(2) {
  animation-delay: 8s;
}
.BannerSection .cs_banner img:nth-of-type(3) {
  animation-delay: 4s;
}
.BannerSection .cs_banner img:nth-of-type(4) {
  animation-delay: 0;
}
.BannerSection .cs_banner img.bg {
  animation: none;
  z-index: 0;
  width: 670px;
  height: 520px;
  position: absolute;
  left: -74px;
  top: 95px;
}
@keyframes multiple-image-crossfade {
  0% {
    opacity: 1;
  }
  17% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  92% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 992px) {
  .BannerSection h1 {
    font-size: 4rem;
  }
  .BannerSection .Subscribe .basic_btn {
    max-width: 270px;
    margin-top: 10px;
  }
}
@media screen and (max-width: 768px) {
  .BannerSection {
    padding-top: 120px;
  }
  .BannerSection h1 {
    padding-top: 300px;
    font-size: 3rem;
  }
  .BannerSection .cs_banner {
    min-height: 400px;
  }
  .BannerSection .cs_banner img {
    left: 0px;
    width: auto;
    height: 550px;
  }
  .BannerSection .cs_banner img.bg {
    width: auto;
    height: 550px;
    left: 7px;
  }
}