.News {
  padding-bottom: 300px;
}
.News .news_space_placeholder {
  min-height: 400px;
  display: flex;
  flex-wrap: wrap;
}
.News .blogs_space_placeholder {
  min-height: 400px;
  display: flex;
  flex-wrap: wrap;
}
.News .blog_headline {
  margin-top: 120px;
}

@media screen and (max-width: 768px) {
  .News {
    padding-bottom: 50px;
    padding-top: 50px;
  }
  .News .blog_headline {
    margin-top: 70px;
  }
}