.SectionHeadline {
  font-size: 54px;
  text-align: center;
  font-family: "Cabin", sans-serif;
  font-weight: bold;
}
.SectionHeadline.x_small {
  font-size: 24px;
}
.SectionHeadline.small {
  font-size: 36px;
}
.SectionHeadline.dark {
  color: #413d37;
}
.SectionHeadline.darker {
  color: #4a463f;
}
.SectionHeadline.light {
  color: #fff;
}
.SectionHeadline.left {
  text-align: left;
}
.SectionHeadline.right {
  text-align: right;
}