.TopRead {
  padding: 0 0 200px 0;
}
.TopRead .cs_newsletter_head {
  font-family: "Cabin", sans-serif;
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #413d37;
  margin-left: 115px;
}
.TopRead .cs_newsletter_small {
  padding-top: 30px;
  display: block;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #655f56;
}
.TopRead .cs_newsletter_small a {
  text-decoration: underline;
}
.TopRead .cs_newsletter {
  position: relative;
}
.TopRead .cs_newsletter img {
  position: absolute;
  left: 5px;
  top: -40px;
  z-index: -1;
}

@media screen and (max-width: 992px) {
  .TopRead .cs_newsletter {
    position: relative;
    margin-top: 100px;
  }
}