@import "../../../../../../../App/variables.scss";

.NewestItem {
  background: $white;
  border-radius: 6px;

  .cs_imgwrap {
    height: 305px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    overflow: hidden;
    text-align: center;

    img {
      object-fit: cover;
      min-height: 100%;
      width: 100%;
    }
  }

  .cs_contentwrap {
    padding: 17px 20px 15px 20px;

    .cs_title {
      font-family: $h_font_family;
      font-size: 18px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.33;
      letter-spacing: normal;
      color: $dark_brown;
      margin-bottom: 2px;
    }

    .cs_text {
      font-family: $t_font_family;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: $lighter_brown;
      margin-bottom: 10px;
    }

    .cs_date {
      font-family: $t_font_family;
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: $lighter_brown;
    }
  }
}

@media screen and (max-width: $break-large) {
  .NewestItem {
    .cs_imgwrap {
      height: 205px;
    }
  }
}
