.AboutVideo {
  margin-bottom: 52px;
}
.AboutVideo .cs_videowrap {
  width: 350px;
  height: 304px;
  margin: 10px auto;
  border-radius: 10px;
  position: relative;
  background-color: black;
}
.AboutVideo .cs_videowrap img {
  height: 100%;
  width: 100%;
}
.AboutVideo .cs_videowrap .player-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 102px;
  width: 102px;
  background: url("./../../../../../../assets/images/play-button-normal.svg");
  background-size: cover;
  background-position: center;
  cursor: pointer;
}
.AboutVideo .cs_videowrap .player-button:hover {
  background: url("./../../../../../../assets/images/play-button-hover.svg");
  background-size: cover;
  background-position: center;
  transition: 0.5s ease;
}
.AboutVideo .cs_title {
  padding-top: 5px;
  display: block;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: normal;
  text-align: left;
  color: #413d37;
  margin: 0;
}
.AboutVideo .cs_text {
  padding-top: 5px;
  display: block;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  text-align: left;
  color: #655f56;
  margin: 0;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .AboutVideo .cs_videowrap {
    width: 300px;
    height: 280px;
  }
}
@media screen and (max-width: 992px) {
  .AboutVideo {
    margin-bottom: 0px;
  }
}
@media screen and (max-width: 380px) {
  .AboutVideo .cs_videowrap {
    width: 290px;
    height: 270px;
  }
}