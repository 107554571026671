@import "../../../../App/variables.scss";

.Partners {
  background: linear-gradient(0deg, #ffd8b6 0%, #ffb97b 100%);
  position: relative;
  padding-bottom: 380px;

  .PartnersItem {
    padding-top: 20px;

    img {
      max-width: 200px;
      max-height: 80px;
    }
  }
}

@media screen and (max-width: $break-massive) {
  .Partners {
    padding-top: 0;
    padding-bottom: 120px;
  }
}

@media screen and (max-width: $break-small) {
  .Partners {
    padding-bottom: 100px;
  }
}
