@import "../../../../App/variables.scss";

.Team {
  padding-bottom: 300px;
  position: relative;

  p {
    &.full-width {
      max-width: fit-content;
    }
  }

  .cs_wave {
    width: 100%;
    position: absolute;
    bottom: -300px;
  }
}

@media screen and (max-width: $break-xlarge) {
  .Team {
    .cs_wave {
      bottom: -180px;
    }
  }
}

@media screen and (max-width: $break-extra) {
  .Team {
    padding-bottom: 200px;
    .cs_wave {
      bottom: -150px;
    }
  }
}

@media screen and (max-width: $break-large) {
  .Team {
    padding-bottom: 150px;
    .cs_wave {
      bottom: -100px;
    }
  }
}

@media screen and (max-width: $break-small) {
  .Team {
    .cs_wave {
      bottom: -50px;
    }
  }
}
