@import "../../../../App/variables.scss";

.Newsletter {
  background-color: $white;
  padding-bottom: 120px;
  position: relative;

  .cs_wave {
    width: 100%;
    position: absolute;
    top: 0;
    margin-top: -200px;
  }

  .z-maxed {
    z-index: 99;
  }

  small {
    color: $white;
    font-family: $t_font_family;
    max-width: 568px;
    width: 100%;
    display: block;
    margin: 0 auto;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;

    a {
      color: $white;
      text-decoration: underline;
    }
  }

  .newsletter_big_width {
    max-width: 800px;
  }
}

@media screen and (max-width: $break-large) {
  .Newsletter {
    .cs_wave {
      margin-top: -120px;
    }
  }
}

@media screen and (max-width: $break-small) {
  .Newsletter {
    padding-bottom: 55px;
    padding-top: 45px;

    .cs_wave {
      margin-top: -50px;
    }
  }
}
