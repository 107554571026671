@import "../../../../App/variables.scss";

.TopRead {
  padding: 0 0 200px 0;

  .cs_newsletter_head {
    font-family: $h_font_family;
    font-size: 24px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: $dark_brown;
    margin-left: 115px;
  }

  .cs_newsletter_small {
    padding-top: 30px;
    display: block;
    font-family: $t_font_family;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: $lighter_brown;
    a {
      text-decoration: underline;
    }
  }

  .cs_newsletter {
    position: relative;

    img {
      position: absolute;
      left: 5px;
      top: -40px;
      z-index: -1;
    }
  }
}

@media screen and (max-width: $break-large) {
  .TopRead {
    .cs_newsletter {
      position: relative;
      margin-top: 100px;
    }
  }
}
