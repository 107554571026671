.Questionnaire {
  padding-top: 170px;
  min-height: 800px;
  background: linear-gradient(to bottom, #eeeadf, #fff);
  padding-bottom: 100px;
}
.Questionnaire h1 {
  font-size: 54px;
  font-weight: bold;
  font-style: normal;
  font-family: "Cabin", sans-serif;
  font-stretch: normal;
  line-height: 1.12;
  letter-spacing: normal;
  color: #413d37;
  padding-top: 0px;
}
.Questionnaire .cs_top_img {
  max-width: 700px;
  left: 0px;
  top: -50px;
  position: absolute;
}
.Questionnaire .cs_contact_info p {
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #413d37;
}
.Questionnaire .cs_contact_info a {
  display: block;
  font-family: "Open Sans", sans-serif;
  color: #413d37;
  font-size: 18px;
}
.Questionnaire .cs_socials a {
  display: inline-block;
  margin-right: 40px;
}
.Questionnaire .cs_socials a.disabled {
  pointer-events: none;
  cursor: default;
}
.Questionnaire .mapped-wrapper {
  position: relative;
}
.Questionnaire .mapped-wrapper .image-wrapper {
  position: absolute;
  z-index: 1000;
  left: 60px;
  top: 60px;
}
.Questionnaire .mapped-wrapper .image-wrapper img {
  width: 240px;
  display: block;
}
.Questionnaire .question-area {
  margin-top: 140px;
}

@media screen and (max-width: 992px) {
  .Questionnaire h1 {
    font-size: 4rem;
  }
  .Questionnaire .cs_top_img {
    left: 0px;
    top: -50px;
  }
  .Questionnaire .cs_socials a {
    display: inline-block;
    margin-right: 20px;
    z-index: 9;
    width: 40px;
  }
  .Questionnaire .cs_socials a img {
    max-width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .Questionnaire {
    padding-top: 70px;
  }
  .Questionnaire h1 {
    padding-top: 0px;
  }
  .Questionnaire .cs_top_img {
    max-height: 450px;
    margin-left: -30px;
    top: 20px;
    position: relative;
    margin-bottom: 20px;
  }
  .Questionnaire .mapped-wrapper {
    position: relative;
  }
  .Questionnaire .mapped-wrapper .image-wrapper {
    position: absolute;
    width: 100%;
    z-index: 1000;
    left: 0px;
    top: 10px;
    text-align: center;
  }
  .Questionnaire .mapped-wrapper .image-wrapper img {
    width: 150px;
    max-width: 40%;
    margin: 5px;
    display: inline-block;
  }
}