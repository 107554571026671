.Partners {
  background: linear-gradient(0deg, #ffd8b6 0%, #ffb97b 100%);
  position: relative;
  padding-bottom: 380px;
}
.Partners .PartnersItem {
  padding-top: 20px;
}
.Partners .PartnersItem img {
  max-width: 200px;
  max-height: 80px;
}

@media screen and (max-width: 2560px) {
  .Partners {
    padding-top: 0;
    padding-bottom: 120px;
  }
}
@media screen and (max-width: 768px) {
  .Partners {
    padding-bottom: 100px;
  }
}