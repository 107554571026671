@import "../../../../App/variables.scss";

.AboutProduct {
  padding-top: 60px;
  height: auto;

  p {
    &.full-width {
      max-width: 920px;
    }
  }
}
