.Privacy {
  background: #eeeadf;
  padding-top: 120px;
  padding-bottom: 120px;
}
.Privacy .privacy-content {
  max-width: 920px;
  margin: 0 auto;
}
.Privacy .privacy-content a {
  color: #ff672e;
}
.Privacy .privacy-content a:hover {
  text-decoration: underline;
}
.Privacy .privacy-content ul {
  font-family: "Open Sans", sans-serif;
  color: #655f56;
  font-size: 18px;
  font-weight: normal;
}
.Privacy .privacy-content ul li {
  margin-top: 8px;
}
.Privacy .privacy-content .mid-list {
  padding-top: 18px;
}

@media screen and (max-width: 768px) {
  .Privacy {
    padding-top: 70px;
  }
}