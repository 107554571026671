@import "../../App/variables.scss";

.CSModal {
  &.checkbox-modal {
    .modal-body {
      padding: 45px 30px;
    }
    p {
      font-family: $t_font_family;
      font-size: 14px;
      text-align: left;
      max-width: calc(100% - 50px);
      display: inline-block;

      a {
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .newslatter-err {
      position: relative;
      margin: 0 0 10px 25px;
    }

    input[type="checkbox"] {
      display: inline-block;
      position: absolute;
      margin-top: 10px;
      left: -5px;
    }

    .modal-content {
      max-width: 553px;
    }
  }

  &.success-modal {
    .modal-content {
      max-width: 580px;
    }
    svg {
      margin-top: 20px;
    }
    p {
      font-family: $t_font_family;
      padding-top: 30px;
      font-size: 18px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: center;
      color: #413d37;
    }
  }

  .modal-content {
    max-width: 500px;
    margin: 0 auto;
  }

  .modal-body {
    padding: 30px;
    text-align: center;
  }

  .cs_modal_headline {
    color: $dark_brown;
    font-family: $h_font_family;
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
    text-align: center;

    &.news {
      margin-top: 40px;
    }
  }

  .cs_modal_closer {
    background: none;
    border: none;
    padding: 15px;
    position: absolute;
    right: 0px;
    top: 0px;

    img {
      &:nth-child(2) {
        display: none;
      }
    }
  }

  .cs_modal_txt {
    padding-top: 25px;
    color: $lighter_brown;
    font-family: $t_font_family;
    font-size: 14px;
    text-align: center;
    margin-bottom: 10px;

    a {
      color: #ff7554;
    }
  }

  .cs_modal_hireimg {
    width: 80px;
    margin: 20px 0 30px 0;
  }

  .cs_modal_newsimg img {
    width: 100% !important;
    margin: 20px 0 30px 0;
  }

  &.cs-video-modal {
    .modal-content {
      background-color: transparent;
      border: none;
      max-width: 700px !important;
      width: 100% !important;

      .modal-body {
        background-color: transparent;

        .cs_modal_closer {
          img {
            &:first-child {
              display: none;
            }
            &:nth-child(2) {
              display: block;
              position: absolute;
              top: -30px;
              left: -30px;
            }
          }
        }
        @media screen and (max-width: 768px) {
          padding: 30px 0px;
        }
      }
    }

    .modal-dialog {
      max-width: 100%;
    }
  }
}
