$dark_brown: #413d37;
$lighter_brown: #655f56;
$light_brown: #eeeadf;
$brown: #4a463f;
$light_brown_trans: linear-gradient(to bottom, #eeeadf, #fff);
$orange_trans: linear-gradient(to top, #ff7554, #ffb070);
$white: #fff;
$orange: #ffb97b;
$dark_orange: #ff815a;
$light_grey: #ada79f;
$dark_brown_opacity: rgba(65, 61, 55, 0.31);

$h_font_family: "Cabin", sans-serif;
$t_font_family: "Open Sans", sans-serif;

$break-small: 768px;
$break-large: 992px;
$break-extra: 1200px;
$break-xlarge: 1920px;
$break-massive: 2560px;
