.AboutUs {
  padding-top: 120px;
}
.AboutUs .container .cs_about {
  position: relative;
  min-height: 660px;
  padding-top: 30px;
}
.AboutUs .container .cs_about img {
  position: absolute;
  right: 0px;
  width: 515px;
}
.AboutUs .container .cs_about img.bg-img {
  z-index: 0;
  width: 670px;
  height: 520px;
  position: absolute;
  left: -52px;
  top: 71px;
}
.AboutUs .cs_quote {
  background-image: url("./../../../../../assets//images/uvodzovky.svg");
  background-repeat: no-repeat;
  background-position: right;
  margin-top: 40px;
}
.AboutUs .cs_quote p {
  font-size: 31px;
  color: #8c8478;
  font-family: "Cabin", sans-serif;
  font-style: italic;
}
.AboutUs .cs_quote p .bold-text {
  font-size: 34px;
  color: #544f48;
  font-weight: 700;
}
.AboutUs h2 {
  padding-top: 80px;
}
.AboutUs p {
  max-width: 450px;
  margin-left: 0px;
}
.AboutUs .cs_isohead {
  font-size: 24px;
  font-weight: bold;
  margin: 26px 0 16px 0;
  color: #413d37;
}
.AboutUs a.cs_cert {
  padding: 23px;
  max-width: calc(50% - 30px);
  width: 100%;
  margin-top: 10px;
  display: inline-block;
  border-radius: 8px;
  text-align: center;
  background: #eeeadf;
  font-weight: bold;
  font-size: 18px;
  color: #413d37;
}
.AboutUs a:first-of-type {
  margin-right: 30px;
}

@media screen and (max-width: 1200px) {
  .AboutUs .container .cs_about img.bg-img {
    left: -141px;
  }
}
@media screen and (max-width: 992px) {
  .AboutUs .container .cs_about img.bg-img {
    left: -262px;
  }
}
@media screen and (max-width: 768px) {
  .AboutUs {
    min-height: auto;
  }
  .AboutUs h2 {
    padding-top: 100px;
  }
  .AboutUs .container .cs_about {
    min-height: 400px;
    width: 600px;
    position: relative;
    right: auto;
  }
  .AboutUs .container .cs_about img {
    left: 0px;
    width: auto;
    height: 550px;
  }
  .AboutUs .container .cs_about img.bg-img {
    width: auto;
    height: 550px;
    left: 0px;
    top: 60px;
  }
  .AboutUs .cs_quote {
    background-image: url("./../../../../../assets//images/uvodzovky.svg");
    background-repeat: no-repeat;
    background-position: right;
    margin-top: 250px;
  }
}