@import "../../App/variables.scss";

.Questionnaire {
  padding-top: 170px;
  min-height: 800px;
  background: $light_brown_trans;
  padding-bottom: 100px;

  h1 {
    font-size: 54px;
    font-weight: bold;
    font-style: normal;
    font-family: $h_font_family;
    font-stretch: normal;
    line-height: 1.12;
    letter-spacing: normal;
    color: $dark_brown;
    padding-top: 0px;
  }

  .cs_top_img {
    max-width: 700px;
    left: 0px;
    top: -50px;
    position: absolute;
  }

  .cs_contact_info {
    p {
      font-family: $t_font_family;
      font-size: 18px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: $dark_brown;
    }

    a {
      display: block;
      font-family: $t_font_family;
      color: $dark_brown;
      font-size: 18px;
    }
  }

  .cs_socials {
    a {
      display: inline-block;
      margin-right: 40px;
      &.disabled {
        pointer-events: none;
        cursor: default;
      }
    }
  }
  .mapped-wrapper {
    position: relative;
    .image-wrapper {
      position: absolute;
      z-index: 1000;
      left: 60px;
      top: 60px;
      img {
        width: 240px;
        display: block;
      }
    }
  }

  .question-area {
    margin-top: 140px;
  }
}

@media screen and (max-width: $break-large) {
  .Questionnaire {
    h1 {
      font-size: 4rem;
    }

    .cs_top_img {
      left: 0px;
      top: -50px;
    }

    .cs_socials {
      a {
        display: inline-block;
        margin-right: 20px;
        z-index: 9;
        width: 40px;

        img {
          max-width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: $break-small) {
  .Questionnaire {
    padding-top: 70px;
    h1 {
      padding-top: 0px;
    }

    .cs_top_img {
      max-height: 450px;
      margin-left: -30px;
      top: 20px;
      position: relative;
      margin-bottom: 20px;
    }
    .mapped-wrapper {
      position: relative;
      .image-wrapper {
        position: absolute;
        width: 100%;
        z-index: 1000;
        left: 0px;
        top: 10px;
        text-align: center;
        img {
          width: 150px;
          max-width: 40%;
          margin: 5px;
          display: inline-block;
        }
      }
    }
  }
}
