@import "../App/variables.scss";

.Header {
  width: 100%;
  position: fixed;
  z-index: 999;
  height: 100px;
  background: $light_brown;

  .container {
    max-width: 1440px;
  }

  .cs_mobile_menu {
    display: none;
  }

  .cs_menu_trigger {
    display: none;
  }

  img {
    max-height: 43px;
    margin-top: 25px;
  }

  nav {
    margin-top: 35px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    div {
      display: flex;
    }

    a {
      margin: 0 10px;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 600;
      color: #655f56;
      font-family: $t_font_family;
      &:hover {
        color: #70695f;
      }

      &:first-child {
        margin-left: 0px;
      }

      /* 
           LOGIN BUTTON
           
           &:nth-child(6) {
                margin-right: 35px;
            }*/
    }
  }
}

@media screen and (max-width: $break-large) {
  .Header {
    .cs_menu_trigger {
      display: inline-block;
      background: none;
      border: none;
      margin-top: 8px;
    }

    .cs_mobile_menu {
      position: absolute;
      height: 100vh;
      display: block;
      left: 0px;
      z-index: 99;
      width: 100%;
      background: $light_brown;

      nav {
        padding-top: 50px;
        display: block;

        div {
          flex-direction: column;
        }

        a {
          max-width: 180px;
          text-align: center;
          display: block;
          padding: 5px;
          margin: 5px auto;

          &:first-child {
            margin-top: 10px;
          }

          &:last-child {
            margin-bottom: 10px;
          }

          &.btn-questionnaire {
            max-width: 100%;
          }

          &.NavLink {
            &.active {
              &::before {
                width: 100%;
                left: 0px;
              }
            }
          }
        }

        button {
          margin: 20px auto;
          display: block;
        }
      }
    }

    nav {
      display: none;
    }
  }
}
