@import "../App/variables.scss";

.Footer {
  background-image: linear-gradient(to bottom, #585149, #413d37);
  position: relative;
  padding-top: 80px;
  padding-bottom: 105px;

  .cs_logo {
    max-height: 72px;
    margin-top: 25px;
  }

  small {
    font-size: 14px;
    font-family: $t_font_family;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: $light_grey;
    margin-top: -56px;
    display: block;
  }

  nav {
    margin-top: 40px;
    display: flex;
    flex-direction: column;

    div {
      display: flex;
    }

    a {
      margin: 0 10px;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 600;
      font-family: $t_font_family;
      color: $white;

      &:first-child {
        margin-left: 0px;
      }

      /* 
           LOGIN BUTTON
           
           &:nth-child(6) {
                margin-right: 35px;
            }*/
    }

    &:last-child {
      align-items: flex-end;
    }

    button {
      margin-top: 75px;
      width: 160px;
    }
  }

  .cs_wave {
    position: absolute;
    margin-top: -4%;
    top: 0px;
    width: 100%;
    margin-left: -5%;
  }
}

@media screen and (max-width: $break-large) {
  .Footer {
    small {
      padding-top: 10px;
      margin-top: 48px;
      text-align: center;
    }

    .basic_btn {
      display: block;
      margin: 20px auto 0 auto;
    }

    .cs_logo {
      display: block;
      margin: 0 auto;
    }

    nav {
      margin-top: 10px;

      div {
        flex-direction: column;
      }

      a {
        margin: 5px auto;
        text-transform: uppercase;
        text-align: center;
        display: block;
        font-size: 14px;
        font-weight: 600;
        font-family: $t_font_family;
        color: $white;
        max-width: 120px;

        &:first-child {
          margin-top: 10px;
          margin-left: auto;
        }

        &:nth-child(6) {
          margin-right: auto;
        }

        &::last-child {
          margin-bottom: 10px;
        }
      }
      &:last-child {
        align-items: center;
      }
    }
  }
}
