.ReadItem {
  background: #fff;
  border-radius: 6px;
}
.ReadItem .cs_imgwrap {
  height: 180px;
  width: 180px;
  border-top-left-radius: 4px;
  overflow: hidden;
  text-align: center;
  display: inline-block;
  vertical-align: top;
}
.ReadItem .cs_imgwrap img {
  object-fit: cover;
  min-height: 100%;
  width: 100%;
}
.ReadItem .cs_contentwrap {
  padding: 0px 20px 15px 20px;
  width: calc(100% - 180px);
  max-height: 180px;
  display: inline-block;
}
.ReadItem .cs_contentwrap .cs_title {
  font-family: "Cabin", sans-serif;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #413d37;
  margin-bottom: 2px;
}
.ReadItem .cs_contentwrap .cs_text {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  height: 111px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #655f56;
  margin-bottom: 10px;
}
.ReadItem .cs_contentwrap .cs_date {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #655f56;
}

@media screen and (max-width: 768px) {
  .ReadItem .cs_imgwrap {
    display: block;
    width: 100%;
  }
  .ReadItem .cs_contentwrap {
    padding: 20px 0px 30px 0px;
    display: block;
    width: 100%;
    max-height: inherit;
  }
  .ReadItem .cs_contentwrap .cs_text {
    height: auto;
  }
}