.FormSuccess {
  padding-top: 0px;
  min-height: 900px;
}
.FormSuccess .cs_success {
  position: relative;
  min-height: 660px;
  padding-top: 50px;
}
.FormSuccess .cs_success img {
  position: absolute;
  right: -75px;
  width: 680px;
}
.FormSuccess .cs_success img.bg {
  z-index: 0;
  width: 670px;
  height: 700px;
  position: absolute;
  left: -200px;
  top: 190px;
}
.FormSuccess h2 {
  padding-top: 200px;
}
.FormSuccess .cs_isohead {
  font-size: 24px;
  font-weight: bold;
  margin: 26px 0 16px 0;
  color: #413d37;
}
.FormSuccess a.cs_cert {
  padding: 23px;
  max-width: calc(50% - 30px);
  width: 100%;
  margin-top: 10px;
  display: inline-block;
  border-radius: 8px;
  text-align: center;
  background: #eeeadf;
  font-weight: bold;
  font-size: 18px;
  color: #413d37;
}
.FormSuccess a:first-of-type {
  margin-right: 30px;
}

@media screen and (max-width: 992px) {
  .FormSuccess a.cs_cert {
    max-width: 45%;
    padding: 10px;
  }
  .FormSuccess .cs_success {
    min-height: 650px;
  }
  .FormSuccess .cs_success img.bg {
    width: auto;
    height: 700px;
    left: -343px;
    top: 158px;
  }
  .FormSuccess .cs_quote {
    margin-top: 220px;
  }
}
@media screen and (max-width: 768px) {
  .FormSuccess {
    min-height: auto;
  }
  .FormSuccess h2 {
    padding-top: 100px;
  }
  .FormSuccess .cs_success {
    min-height: 400px;
    margin-bottom: 250px;
  }
  .FormSuccess .cs_success img {
    left: -75px;
    width: auto;
    height: 700px;
  }
  .FormSuccess .cs_success img.bg {
    width: auto;
    height: 350px;
    left: 20px;
    top: 190px;
  }
}