@import "../../../../App/variables.scss";

.FormSuccess {
  padding-top: 0px;
  min-height: 900px;

  .cs_success {
    position: relative;
    min-height: 660px;
    padding-top: 50px;

    img {
      position: absolute;
      right: -75px;
      width: 680px;

      &.bg {
        z-index: 0;
        width: 670px;
        height: 700px;
        position: absolute;
        left: -200px;
        top: 190px;
      }
    }
  }

  h2 {
    padding-top: 200px;
  }

  .cs_isohead {
    $h_font_family: "Cabin", sans-serif;
    font-size: 24px;
    font-weight: bold;
    margin: 26px 0 16px 0;
    color: $dark_brown;
  }

  a {
    &.cs_cert {
      padding: 23px;
      max-width: calc(50% - 30px);
      width: 100%;
      margin-top: 10px;
      display: inline-block;
      border-radius: 8px;
      text-align: center;
      background: $light_brown;
      font-weight: bold;
      font-size: 18px;
      color: $dark_brown;
    }

    &:first-of-type {
      margin-right: 30px;
    }
  }
}

@media screen and (max-width: $break-large) {
  .FormSuccess {
    a {
      &.cs_cert {
        max-width: 45%;
        padding: 10px;
      }
    }
    .cs_success {
      min-height: 650px;

      img {
        &.bg {
          width: auto;
          height: 700px;
          left: -343px;
          top: 158px;
        }
      }
    }
    .cs_quote {
      margin-top: 220px;
    }
  }
}

@media screen and (max-width: $break-small) {
  .FormSuccess {
    min-height: auto;

    h2 {
      padding-top: 100px;
    }
    .cs_success {
      min-height: 400px;
      margin-bottom: 250px;

      img {
        left: -75px;
        width: auto;
        height: 700px;

        &.bg {
          width: auto;
          height: 350px;
          left: 20px;
          top: 190px;
        }
      }
    }
  }
}
