.login-btn {
  margin-left: 20px;
  z-index: 9999;
}

.NavLink {
  position: relative;

  &:before {
    content: "";
    width: 0%;
    transition: all 0.2s;
  }

  &.active {
    &:before {
      content: "";
      width: 140%;
      background-image: linear-gradient(to top, #ff7554, #ffb070);
      height: 3px;
      position: absolute;
      bottom: -4px;
      left: -20%;
      border-radius: 5px;
      transition: all 0.2s;
    }
  }

  &.external {
    color: #ff815a;
    padding: 0 20px;
    &:hover {
      color: #ff815a;
    }
  }
}

.NavButton {
  position: relative;
  &.active {
    display: none !important;
  }
}
@media screen and (max-width: 1200px) {
  .NavLink {
    &.external {
      padding: 0;
    }
  }

  .basic_btn.navigation.nav-btn {
    width: 135px;
  }

  .basic_btn.bordered.is-small.nav-btn {
    width: 120px;
  }
}
