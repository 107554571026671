.SectionUnderText {
  font-size: 18px;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  max-width: 542px;
  margin: 0 auto;
  padding-top: 10px;
}
.SectionUnderText.small {
  font-size: 14px;
}
.SectionUnderText.dark {
  color: #655f56;
}
.SectionUnderText.darker {
  color: #4a463f;
}
.SectionUnderText.light {
  color: #fff;
}
.SectionUnderText.left {
  text-align: left;
}
.SectionUnderText.right {
  text-align: right;
}
.SectionUnderText.bold {
  font-weight: bold;
}
.SectionUnderText.no-padding {
  padding: 0px;
}