@import "../../../../App/variables.scss";

.Newest {
  padding-bottom: 150px;
  padding-top: 50px;
  background: $light_brown;
  position: relative;

  .cs_wave {
    position: absolute;
    bottom: -5px;
    width: 100%;
  }
}
