.Solution {
  padding-top: 120px;
  height: auto;
  padding-bottom: 260px;
  position: relative;
}
.Solution p.full-width {
  max-width: 920px;
}
.Solution .toggle-buttons {
  text-align: center;
  margin: 60px auto 0px auto;
  background-color: #eeeadf;
  width: fit-content;
  display: flex;
  justify-content: center;
  height: fit-content;
  border-radius: 27.5px;
}
.Solution .toggle-buttons button {
  height: 56px;
  width: 270px;
  border-radius: 27.5px;
  font-size: 18px;
  font-weight: bold;
  background-color: transparent;
  color: #413d37;
  border: none;
}
.Solution .toggle-buttons .is-active {
  background-color: #ff8b5f;
  color: #fff;
}
.Solution .img-right {
  height: 620px;
  margin-bottom: 95px;
}
.Solution .accordion {
  margin: 140px 0 auto 0;
}
.Solution .cs_wave {
  width: 100%;
  position: absolute;
  max-height: 100%;
}

@media screen and (max-width: 992px) {
  .Solution .img-right {
    width: 100%;
    height: auto !important;
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 768px) {
  .Solution .img-right {
    max-height: 475px !important;
  }
  .Solution .toggle-buttons {
    width: auto;
    margin-bottom: 50px;
  }
  .Solution .accordion {
    margin: auto 0;
    padding-bottom: 0px;
  }
}
@media screen and (max-width: 420px) {
  .Solution .img-right {
    max-height: 320px !important;
  }
}