@import url("https://fonts.googleapis.com/css?family=Cabin:400,500,600,700|Open+Sans:400,600,700&display=swap");
* {
  outline: none !important;
  -webkit-appearance: none;
}

body {
  margin: 0;
  padding: 0;
}
body.modal-open {
  padding-right: 0px !important;
}
body.no-scroll {
  overflow: hidden;
}

a {
  text-decoration: none;
  color: #413d37;
  outline: none;
}
a:hover {
  text-decoration: none;
  color: #655f56;
}

p {
  font-family: "Open Sans", sans-serif;
  color: #655f56;
  font-size: 18px;
  font-weight: normal;
}

.App {
  overflow: hidden;
}