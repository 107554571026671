.Newsletter {
  background-color: #fff;
  padding-bottom: 120px;
  position: relative;
}
.Newsletter .cs_wave {
  width: 100%;
  position: absolute;
  top: 0;
  margin-top: -200px;
}
.Newsletter .z-maxed {
  z-index: 99;
}
.Newsletter small {
  color: #fff;
  font-family: "Open Sans", sans-serif;
  max-width: 568px;
  width: 100%;
  display: block;
  margin: 0 auto;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}
.Newsletter small a {
  color: #fff;
  text-decoration: underline;
}
.Newsletter .newsletter_big_width {
  max-width: 800px;
}

@media screen and (max-width: 992px) {
  .Newsletter .cs_wave {
    margin-top: -120px;
  }
}
@media screen and (max-width: 768px) {
  .Newsletter {
    padding-bottom: 55px;
    padding-top: 45px;
  }
  .Newsletter .cs_wave {
    margin-top: -50px;
  }
}