@import "../../../../App/variables.scss";

.AboutUs {
  // padding-top: 0px;
  // min-height: 900px;
  padding-top: 120px;

  .container {
    .cs_about {
      // position: relative;
      // min-height: 660px;
      // margin-top: 100px;
      position: relative;
      min-height: 660px;
      padding-top: 30px;

      img {
        // position: absolute;
        // right: 60px;
        // width: 680px;
        position: absolute;
        right: 0px;
        width: 515px;

        &.bg-img {
          // z-index: 0;
          // width: 670px;
          // height: 700px;
          // position: absolute;
          // left: -200px;
          // top: 50px;
          z-index: 0;
          width: 670px;
          height: 520px;
          position: absolute;
          left: -52px;
          top: 71px;
        }
      }
    }
  }

  .cs_quote {
    background-image: url("./../../../../../assets//images/uvodzovky.svg");
    background-repeat: no-repeat;
    background-position: right;
    margin-top: 40px;
    p {
      font-size: 31px;
      color: #8c8478;
      font-family: "Cabin", sans-serif;
      font-style: italic;

      .bold-text {
        font-size: 34px;
        color: #544f48;
        font-weight: 700;
      }
    }
  }

  h2 {
    padding-top: 80px;
  }

  p {
    max-width: 450px;
    margin-left: 0px;
  }

  .cs_isohead {
    $h_font_family: "Cabin", sans-serif;
    font-size: 24px;
    font-weight: bold;
    margin: 26px 0 16px 0;
    color: $dark_brown;
  }

  a {
    &.cs_cert {
      padding: 23px;
      max-width: calc(50% - 30px);
      width: 100%;
      margin-top: 10px;
      display: inline-block;
      border-radius: 8px;
      text-align: center;
      background: $light_brown;
      font-weight: bold;
      font-size: 18px;
      color: $dark_brown;
    }

    &:first-of-type {
      margin-right: 30px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .AboutUs {
    .container {
      .cs_about {
        img {
          &.bg-img {
            left: -141px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $break-large) {
  .AboutUs {
    .container {
      .cs_about {
        img {
          &.bg-img {
            left: -262px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $break-small) {
  .AboutUs {
    min-height: auto;

    h2 {
      padding-top: 100px;
    }
    .container {
      .cs_about {
        min-height: 400px;
        width: 600px;
        position: relative;
        right: auto;
        img {
          left: 0px;
          width: auto;
          height: 550px;
          &.bg-img {
            width: auto;
            height: 550px;
            left: 0px;
            top: 60px;
          }
        }
      }
    }
    .cs_quote {
      background-image: url("./../../../../../assets//images/uvodzovky.svg");
      background-repeat: no-repeat;
      background-position: right;
      margin-top: 250px;
    }
  }
}

// @media screen and (max-width: $break-small) {
//   .AboutUs {
//     min-height: auto;

//     h2 {
//       padding-top: 100px;
//     }
//     .cs_about {
//       min-height: 400px;
//       width: 600px;
//       position: relative;
//       left: -300px;
//       right: auto;

//       img {
//         left: 0px;
//         width: auto;
//         height: auto;

//         &.bg {
//           width: auto;
//           height: 350px;
//           left: 0px;
//           top: 100px;
//         }
//       }
//     }
//   }
// }
