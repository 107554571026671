@import "../../../../App/variables.scss";

.News {
  padding-bottom: 300px;

  .news_space_placeholder {
    min-height: 400px;
    display: flex;
    flex-wrap: wrap;
  }
  .blogs_space_placeholder {
    min-height: 400px;
    display: flex;
    flex-wrap: wrap;
  }

  .blog_headline {
    margin-top: 120px;
  }
}

@media screen and (max-width: $break-small) {
  .News {
    padding-bottom: 50px;
    padding-top: 50px;

    .blog_headline {
      margin-top: 70px;
    }
  }
}
