@import "../../App/variables.scss";

.Privacy {
  background: #eeeadf;
  padding-top: 120px;
  padding-bottom: 120px;
  .privacy-content {
    max-width: 920px;
    margin: 0 auto;
    a {
      color: #ff672e;
    }
    a:hover {
      text-decoration: underline;
    }
    ul {
      font-family: $t_font_family;
      color: $lighter_brown;
      font-size: 18px;
      font-weight: normal;

      li {
        margin-top: 8px;
      }
    }
    .mid-list {
      padding-top: 18px;
    }
  }
}

@media screen and (max-width: $break-small) {
  .Privacy {
    padding-top: 70px;
  }
}
