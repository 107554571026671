.Newest {
  padding-bottom: 150px;
  padding-top: 50px;
  background: #eeeadf;
  position: relative;
}
.Newest .cs_wave {
  position: absolute;
  bottom: -5px;
  width: 100%;
}