@import url("https://fonts.googleapis.com/css?family=Cabin:400,500,600,700|Open+Sans:400,600,700&display=swap");
@import "./variables.scss";

* {
  outline: none !important;
  -webkit-appearance: none;
}

body {
  margin: 0;
  padding: 0;

  &.modal-open {
    padding-right: 0px !important;
  }

  &.no-scroll {
    overflow: hidden;
  }
}

a {
  text-decoration: none;
  color: $dark_brown;
  outline: none;

  &:hover {
    text-decoration: none;
    color: $lighter_brown;
  }
}

p {
  font-family: $t_font_family;
  color: $lighter_brown;
  font-size: 18px;
  font-weight: normal;
}

.App {
  overflow: hidden;
}
