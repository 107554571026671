.Questions {
  height: auto;
  position: relative;
  padding-bottom: 120px;
  margin-top: -50px;
}
.Questions h2 {
  margin-bottom: 60px;
}
.Questions .toggle-buttons {
  text-align: center;
  margin-top: 60px;
  margin-bottom: 50px;
}
.Questions img {
  max-height: 570px;
}

@media screen and (max-width: 1920px) {
  .Questions {
    margin-top: -100px;
  }
}
@media screen and (max-width: 992px) {
  .Questions {
    margin-top: -150px;
  }
}
@media screen and (max-width: 768px) {
  .Questions {
    margin-top: -200px;
    padding-bottom: 0px;
  }
}