@import "../../../../../App/variables.scss";

.Post {
  padding-bottom: 120px;
  padding-top: 180px;

  .cs_bg_vawe {
    height: 480px;
    position: absolute;
    width: 100%;
    z-index: -1;
    top: 80px;
    background: $light_brown;

    img {
      width: 100%;
      position: absolute;
      bottom: -3px;
    }
  }

  .SocialMediaShareButton {
    display: inline-block;

    &:focus {
      outline: none;
    }
  }

  .SocialMediaShareCount {
    display: inline-block;
    position: relative;
    outline: none;
  }

  #share-button {
    outline: none;
    border-radius: 3px;
    font-weight: 600;
    padding: 0px 8px;
    display: inline-block;

    &.fb {
      background: #3b5998;

      &:hover {
        cursor: pointer;
        background: #213a6f;
      }

      svg {
        width: 15px;
        fill: white;
        margin-top: -3px;
        vertical-align: middle;
        border-radius: 2px;
      }
    }

    &.linked {
      background: #0073b1;
      margin-right: 50px;

      &:hover {
        cursor: pointer;
        background: #01679e;
      }

      svg {
        width: 15px;
        fill: white;
        margin-top: -5px;
        vertical-align: middle;
        border-radius: 2px;
      }
    }

    span {
      vertical-align: middle;
      color: white;
      font-size: 13px;
      padding: 0 3px 0px 7px;
    }
  }

  .BlogsItem {
    .cs_imgwrap {
      height: 196px;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }

    .cs_contentwrap {
      padding: 17px 20px 0px 0px;

      .cs_text {
        display: none !important;
      }
    }
  }

  .cs_post_body {
    max-width: 730px;
    margin: 0 auto;
    position: relative;

    .cs_count {
      height: 20px;
      width: auto;
      color: #4e4e4e;
      font-family: Arial;
      font-size: 11px;
      margin-left: 10px;
      margin-bottom: -5px;
      font-weight: bold;
      border-radius: 4px;
      line-height: 12px;
      padding: 3px 7px 3px 7px;
      border: 1px solid #d8d8d8;
      text-align: center;
      position: relative;
      float: left;

      &:after {
        position: absolute;
        width: 7px;
        height: 7px;
        border-top: 0px solid #d8d8d8;
        border-right: 1px solid #d8d8d8;
        border-bottom: 1px solid #d8d8d8;
        border-left: 0px solid #d8d8d8;
        top: 100%;
        left: 0%;
        margin-left: -4px;
        content: "";
        transform: rotate(134deg);
        margin-top: -13px;
        background: white;
      }
    }
  }

  .cs_post_headline {
    color: $dark_brown;
    font-family: $h_font_family;
    font-size: 36px;
    font-weight: bold;
    line-height: 46px;
  }

  .cs_post_content {
    color: $lighter_brown;
    font-family: $t_font_family;
    font-size: 18px;
    line-height: 27px;
    padding-top: 30px;
    display: block;
    width: 100%;
    height: auto;
    margin-bottom: 30px;
  }

  .cs_post_img {
    object-fit: cover;

    img {
      width: 100%;
      margin-top: 30px;
    }
  }

  .cs_post_small {
    color: $lighter_brown;
    font-family: $t_font_family;
    font-size: 12px;
    line-height: 18px;
  }

  .cs_post_btn {
    position: absolute;
    left: -180px;
    top: 8px;
    max-width: 160px;
    padding-bottom: 4px;

    svg {
      font-size: 30px;
      margin-top: -3px;
      margin-left: -6px;
    }
  }
}

@media screen and (max-width: $break-extra) {
  .Post {
    .cs_post_body {
      .cs_post_btn {
        position: relative;
        left: auto;
        top: 8px;
        max-width: 160px;
        padding-bottom: 4px;
        margin-bottom: 30px;

        svg {
          font-size: 30px;
          margin-top: -3px;
          margin-left: -6px;
        }
      }
    }
  }
}

@media screen and (max-width: $break-small) {
  .Post {
    padding-bottom: 120px;
    padding-top: 80px;

    .cs_bg_vawe {
      height: 330px;
    }
  }
}
