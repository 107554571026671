@import '../../../../../App/variables.scss';

.TeamItem {
    .cs_imgwrap {
        width: 140px;
        height: 140px;
        margin: 10px auto;
        border-radius: 120px;
        position: relative;

        img {
            &:first-child {
                position: absolute;
                left: -26px;
                top: -16px;
                width: 210px;
                z-index: 0;
            }

            &:nth-child(2) {
                position: relative;
                margin-top: 0px;
                max-width: 100%;
                border-radius: 50%;
            }
        }
    }

    .cs_title {
        padding-top: 5px;
        display: block;
        font-size: 18px;
        font-weight: bold;
        letter-spacing: normal;
        text-align: center;
        color: $dark_brown;
        margin: 0;
    }

    .cs_text {
        padding-top: 5px;
        display: block;
        max-width: 255px;
        margin:0 auto;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        color: $lighter_brown;
        
    }

    .cs_linked {
        display: block;
        margin-top: 10px;
    }
}