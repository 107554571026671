.Header {
  width: 100%;
  position: fixed;
  z-index: 999;
  height: 100px;
  background: #eeeadf;
}
.Header .container {
  max-width: 1440px;
}
.Header .cs_mobile_menu {
  display: none;
}
.Header .cs_menu_trigger {
  display: none;
}
.Header img {
  max-height: 43px;
  margin-top: 25px;
}
.Header nav {
  margin-top: 35px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.Header nav div {
  display: flex;
}
.Header nav a {
  margin: 0 10px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  color: #655f56;
  font-family: "Open Sans", sans-serif;
  /* 
       LOGIN BUTTON

       &:nth-child(6) {
            margin-right: 35px;
        }*/
}
.Header nav a:hover {
  color: #70695f;
}
.Header nav a:first-child {
  margin-left: 0px;
}

@media screen and (max-width: 992px) {
  .Header .cs_menu_trigger {
    display: inline-block;
    background: none;
    border: none;
    margin-top: 8px;
  }
  .Header .cs_mobile_menu {
    position: absolute;
    height: 100vh;
    display: block;
    left: 0px;
    z-index: 99;
    width: 100%;
    background: #eeeadf;
  }
  .Header .cs_mobile_menu nav {
    padding-top: 50px;
    display: block;
  }
  .Header .cs_mobile_menu nav div {
    flex-direction: column;
  }
  .Header .cs_mobile_menu nav a {
    max-width: 180px;
    text-align: center;
    display: block;
    padding: 5px;
    margin: 5px auto;
  }
  .Header .cs_mobile_menu nav a:first-child {
    margin-top: 10px;
  }
  .Header .cs_mobile_menu nav a:last-child {
    margin-bottom: 10px;
  }
  .Header .cs_mobile_menu nav a.btn-questionnaire {
    max-width: 100%;
  }
  .Header .cs_mobile_menu nav a.NavLink.active::before {
    width: 100%;
    left: 0px;
  }
  .Header .cs_mobile_menu nav button {
    margin: 20px auto;
    display: block;
  }
  .Header nav {
    display: none;
  }
}