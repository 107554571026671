@import "../../App/variables.scss";

.SectionUnderText {
  font-size: 18px;
  text-align: center;
  font-family: $t_font_family;
  max-width: 542px;
  margin: 0 auto;
  padding-top: 10px;

  &.small {
    font-size: 14px;
  }

  &.dark {
    color: $lighter_brown;
  }

  &.darker {
    color: $brown;
  }

  &.light {
    color: $white;
  }

  &.left {
    text-align: left;
  }

  &.right {
    text-align: right;
  }

  &.bold {
    font-weight: bold;
  }

  &.no-padding {
    padding: 0px;
  }
}
