.Footer {
  background-image: linear-gradient(to bottom, #585149, #413d37);
  position: relative;
  padding-top: 80px;
  padding-bottom: 105px;
}
.Footer .cs_logo {
  max-height: 72px;
  margin-top: 25px;
}
.Footer small {
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #ada79f;
  margin-top: -56px;
  display: block;
}
.Footer nav {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
}
.Footer nav div {
  display: flex;
}
.Footer nav a {
  margin: 0 10px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
  color: #fff;
  /* 
       LOGIN BUTTON

       &:nth-child(6) {
            margin-right: 35px;
        }*/
}
.Footer nav a:first-child {
  margin-left: 0px;
}
.Footer nav:last-child {
  align-items: flex-end;
}
.Footer nav button {
  margin-top: 75px;
  width: 160px;
}
.Footer .cs_wave {
  position: absolute;
  margin-top: -4%;
  top: 0px;
  width: 100%;
  margin-left: -5%;
}

@media screen and (max-width: 992px) {
  .Footer small {
    padding-top: 10px;
    margin-top: 48px;
    text-align: center;
  }
  .Footer .basic_btn {
    display: block;
    margin: 20px auto 0 auto;
  }
  .Footer .cs_logo {
    display: block;
    margin: 0 auto;
  }
  .Footer nav {
    margin-top: 10px;
  }
  .Footer nav div {
    flex-direction: column;
  }
  .Footer nav a {
    margin: 5px auto;
    text-transform: uppercase;
    text-align: center;
    display: block;
    font-size: 14px;
    font-weight: 600;
    font-family: "Open Sans", sans-serif;
    color: #fff;
    max-width: 120px;
  }
  .Footer nav a:first-child {
    margin-top: 10px;
    margin-left: auto;
  }
  .Footer nav a:nth-child(6) {
    margin-right: auto;
  }
  .Footer nav a::last-child {
    margin-bottom: 10px;
  }
  .Footer nav:last-child {
    align-items: center;
  }
}