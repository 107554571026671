.CSModal.checkbox-modal .modal-body {
  padding: 45px 30px;
}
.CSModal.checkbox-modal p {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  text-align: left;
  max-width: calc(100% - 50px);
  display: inline-block;
}
.CSModal.checkbox-modal p a {
  text-decoration: underline;
  cursor: pointer;
}
.CSModal.checkbox-modal .newslatter-err {
  position: relative;
  margin: 0 0 10px 25px;
}
.CSModal.checkbox-modal input[type=checkbox] {
  display: inline-block;
  position: absolute;
  margin-top: 10px;
  left: -5px;
}
.CSModal.checkbox-modal .modal-content {
  max-width: 553px;
}
.CSModal.success-modal .modal-content {
  max-width: 580px;
}
.CSModal.success-modal svg {
  margin-top: 20px;
}
.CSModal.success-modal p {
  font-family: "Open Sans", sans-serif;
  padding-top: 30px;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #413d37;
}
.CSModal .modal-content {
  max-width: 500px;
  margin: 0 auto;
}
.CSModal .modal-body {
  padding: 30px;
  text-align: center;
}
.CSModal .cs_modal_headline {
  color: #413d37;
  font-family: "Cabin", sans-serif;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  text-align: center;
}
.CSModal .cs_modal_headline.news {
  margin-top: 40px;
}
.CSModal .cs_modal_closer {
  background: none;
  border: none;
  padding: 15px;
  position: absolute;
  right: 0px;
  top: 0px;
}
.CSModal .cs_modal_closer img:nth-child(2) {
  display: none;
}
.CSModal .cs_modal_txt {
  padding-top: 25px;
  color: #655f56;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  text-align: center;
  margin-bottom: 10px;
}
.CSModal .cs_modal_txt a {
  color: #ff7554;
}
.CSModal .cs_modal_hireimg {
  width: 80px;
  margin: 20px 0 30px 0;
}
.CSModal .cs_modal_newsimg img {
  width: 100% !important;
  margin: 20px 0 30px 0;
}
.CSModal.cs-video-modal .modal-content {
  background-color: transparent;
  border: none;
  max-width: 700px !important;
  width: 100% !important;
}
.CSModal.cs-video-modal .modal-content .modal-body {
  background-color: transparent;
}
.CSModal.cs-video-modal .modal-content .modal-body .cs_modal_closer img:first-child {
  display: none;
}
.CSModal.cs-video-modal .modal-content .modal-body .cs_modal_closer img:nth-child(2) {
  display: block;
  position: absolute;
  top: -30px;
  left: -30px;
}
@media screen and (max-width: 768px) {
  .CSModal.cs-video-modal .modal-content .modal-body {
    padding: 30px 0px;
  }
}
.CSModal.cs-video-modal .modal-dialog {
  max-width: 100%;
}