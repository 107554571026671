.Post {
  padding-bottom: 120px;
  padding-top: 180px;
}
.Post .cs_bg_vawe {
  height: 480px;
  position: absolute;
  width: 100%;
  z-index: -1;
  top: 80px;
  background: #eeeadf;
}
.Post .cs_bg_vawe img {
  width: 100%;
  position: absolute;
  bottom: -3px;
}
.Post .SocialMediaShareButton {
  display: inline-block;
}
.Post .SocialMediaShareButton:focus {
  outline: none;
}
.Post .SocialMediaShareCount {
  display: inline-block;
  position: relative;
  outline: none;
}
.Post #share-button {
  outline: none;
  border-radius: 3px;
  font-weight: 600;
  padding: 0px 8px;
  display: inline-block;
}
.Post #share-button.fb {
  background: #3b5998;
}
.Post #share-button.fb:hover {
  cursor: pointer;
  background: #213a6f;
}
.Post #share-button.fb svg {
  width: 15px;
  fill: white;
  margin-top: -3px;
  vertical-align: middle;
  border-radius: 2px;
}
.Post #share-button.linked {
  background: #0073b1;
  margin-right: 50px;
}
.Post #share-button.linked:hover {
  cursor: pointer;
  background: #01679e;
}
.Post #share-button.linked svg {
  width: 15px;
  fill: white;
  margin-top: -5px;
  vertical-align: middle;
  border-radius: 2px;
}
.Post #share-button span {
  vertical-align: middle;
  color: white;
  font-size: 13px;
  padding: 0 3px 0px 7px;
}
.Post .BlogsItem .cs_imgwrap {
  height: 196px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.Post .BlogsItem .cs_contentwrap {
  padding: 17px 20px 0px 0px;
}
.Post .BlogsItem .cs_contentwrap .cs_text {
  display: none !important;
}
.Post .cs_post_body {
  max-width: 730px;
  margin: 0 auto;
  position: relative;
}
.Post .cs_post_body .cs_count {
  height: 20px;
  width: auto;
  color: #4e4e4e;
  font-family: Arial;
  font-size: 11px;
  margin-left: 10px;
  margin-bottom: -5px;
  font-weight: bold;
  border-radius: 4px;
  line-height: 12px;
  padding: 3px 7px 3px 7px;
  border: 1px solid #d8d8d8;
  text-align: center;
  position: relative;
  float: left;
}
.Post .cs_post_body .cs_count:after {
  position: absolute;
  width: 7px;
  height: 7px;
  border-top: 0px solid #d8d8d8;
  border-right: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
  border-left: 0px solid #d8d8d8;
  top: 100%;
  left: 0%;
  margin-left: -4px;
  content: "";
  transform: rotate(134deg);
  margin-top: -13px;
  background: white;
}
.Post .cs_post_headline {
  color: #413d37;
  font-family: "Cabin", sans-serif;
  font-size: 36px;
  font-weight: bold;
  line-height: 46px;
}
.Post .cs_post_content {
  color: #655f56;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  line-height: 27px;
  padding-top: 30px;
  display: block;
  width: 100%;
  height: auto;
  margin-bottom: 30px;
}
.Post .cs_post_img {
  object-fit: cover;
}
.Post .cs_post_img img {
  width: 100%;
  margin-top: 30px;
}
.Post .cs_post_small {
  color: #655f56;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  line-height: 18px;
}
.Post .cs_post_btn {
  position: absolute;
  left: -180px;
  top: 8px;
  max-width: 160px;
  padding-bottom: 4px;
}
.Post .cs_post_btn svg {
  font-size: 30px;
  margin-top: -3px;
  margin-left: -6px;
}

@media screen and (max-width: 1200px) {
  .Post .cs_post_body .cs_post_btn {
    position: relative;
    left: auto;
    top: 8px;
    max-width: 160px;
    padding-bottom: 4px;
    margin-bottom: 30px;
  }
  .Post .cs_post_body .cs_post_btn svg {
    font-size: 30px;
    margin-top: -3px;
    margin-left: -6px;
  }
}
@media screen and (max-width: 768px) {
  .Post {
    padding-bottom: 120px;
    padding-top: 80px;
  }
  .Post .cs_bg_vawe {
    height: 330px;
  }
}