.solutions {
  width: 100%;
  margin: 140px 0 auto;
  font-family: "Cabin", sans-serif;
}
@media screen and (max-width: 768px) {
  .solutions {
    margin-top: 0px;
  }
}

.solutions .solution {
  margin: 15px;
  padding: 15px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #dcd9d6;
  min-height: 84px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.solutions .solution .solution-question {
  position: relative;
  padding-left: 25px;
  transition: all 0.4s ease;
  color: #413d37;
  font-weight: 700;
}

.solutions .solution .solution-question::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  width: 14px;
  height: 8px;
  background-image: url("./../../../../../../assets/images/arrow-down.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.solutions .solution .solution-answer {
  opacity: 0;
  padding: 0 25px;
  max-height: 0;
  overflow-y: hidden;
  color: #655f56;
  font-family: "Open Sans";
  font-size: 14px;
  line-height: 21px;
}

.solutions .solution.open .solution-question {
  margin-bottom: 15px;
}

.solutions .solution.open .solution-question::after {
  transform: translateY(-50%) rotate(180deg);
}

.solutions .solution.open .solution-answer {
  max-height: 1000px;
  opacity: 1;
}

@media (max-width: 500px) {
  .solutions .solution {
    padding: 0;
  }
}
@media (max-width: 520px) {
  .solutions .solution .solution-question :first-child {
    max-width: 300px;
    display: flex;
  }
}
@media (max-width: 450px) {
  .solutions .solution .solution-question :first-child {
    max-width: 200px;
  }
}
@media (max-width: 320px) {
  .solutions .solution .solution-question :first-child {
    max-width: 180px;
  }
}