.AboutItem .cs_imgwrap {
  position: relative;
  width: 226px;
  height: 201px;
  margin: 0 auto -20px auto;
}
.AboutItem .cs_imgwrap img {
  height: 100%;
  width: 100%;
}
.AboutItem .cs_title {
  padding-top: 5px;
  display: block;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: normal;
  text-align: center;
  color: #413d37;
  margin: 0;
}
.AboutItem .cs_text {
  padding-top: 5px;
  display: block;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #655f56;
  margin: 0;
}