@import "../../../../../App/variables.scss";

.AboutItem {
  .cs_imgwrap {
    position: relative;
    width: 226px;
    height: 201px;
    margin: 0 auto -20px auto;

    img {
      height: 100%;
      width: 100%;
    }
  }

  .cs_title {
    padding-top: 5px;
    display: block;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: normal;
    text-align: center;
    color: $dark_brown;
    margin: 0;
  }

  .cs_text {
    padding-top: 5px;
    display: block;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    color: $lighter_brown;
    margin: 0;
  }
}
