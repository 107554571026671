@import "../../App/variables.scss";

.basic_btn {
  font-family: $h_font_family;
  font-size: 18px;
  max-width: 160px;
  width: 100%;
  padding: 10px 10px 9px 10px;
  border-radius: 22.5px;
  font-weight: 500;
  display: inline-block;
  border: none;
  outline: none !important;
  box-shadow: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &.fullwidth {
    max-width: 300px;
    margin-top: 15px;
  }

  &.orange {
    color: $white;
    background-image: $orange_trans;
  }

  &.orange-dark {
    color: $white;
    background-color: $dark_orange;
  }

  &.white {
    color: $dark_brown;
    background-color: $white;
  }

  &.black {
    color: $white;
    background-color: $dark_brown;
  }

  &.bordered {
    background: none;
    max-width: 200px;
    padding: 6px 10px 6px 10px;

    &.is-small {
      max-width: 156px;
    }

    &.light {
      border: solid 3px $white;
      color: $white;

      &.thin-border {
        border: solid 1px $white;
      }
    }

    &.dark {
      border: solid 3px $dark_brown;
      color: $dark_brown;

      &.thin-border {
        border: solid 1px $dark_brown_opacity;
      }
    }
  }

  &.is-bigger {
    padding: 0px auto;
    border-radius: 28px;
    height: 56px;
  }

  &.is-wide {
    max-width: fit-content;
    padding: 0px 40.5px;
  }

  &.is-wider {
    max-width: fit-content;
    padding: 0px 60px;
  }

  &.navigation {
    height: 56px;
    width: 176px;
    border-radius: 28px;
  }

  &.full-width {
    width: 100%;
    max-width: 500px;
  }

  &.btn-questionnaire {
    margin-top: 10px !important;

    &.display-none {
      display: none;
    }
  }

  &.mobile-menu {
    margin-bottom: 0px !important;
  }
}
