@import "./../../../../../App/variables.scss";

.accordion {
  width: 100%;
  &.left {
    margin-right: 30px;
  }
  .question-text {
    text-align: start !important;
  }
  .card {
    background-color: $white;
    margin-bottom: 20px;
    border: 1px solid #dcd9d6 !important;
    border-radius: 8px !important;

    .card-header {
      background-color: $white;
      height: 84px;
      display: flex;
      border-bottom: none;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      border-bottom-left-radius: none;
      border-bottom-right-radius: 8px;

      button {
        color: $dark_brown;
        font-weight: bold;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
    .collapse {
      &.show {
        .card-body {
          background-color: $white;
          border-top: none;
        }
      }
    }
  }
}

@media screen and (max-width: $break-large) {
  .accordion {
    .question-text {
      text-align: center !important;
      margin-bottom: 20px;
    }

    .card {
      .card-header {
        height: fit-content;
        // min-height: 68px;
        padding: 0px;

        button {
          &.questions {
            padding-left: 0px;
            padding-right: 0px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .accordion {
    .card {
      .card-header {
        height: fit-content;
        //min-height: 92px;
      }
    }
  }
}

.accordion > .card:first-of-type {
  border-bottom: 1px solid #dcd9d6;
}

.faqs {
  width: 100%;
  margin: apí0 auto;
  display: flex;
  flex-wrap: wrap;
  font-family: "Cabin", sans-serif;
}

.faqs .faq {
  margin: 15px;
  padding: 15px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #dcd9d6;
  min-height: 84px;
  height: 84px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(50% - 30px);
}

.faqs .faq .faq-question {
  position: relative;
  // font-size: 20px;
  padding-left: 25px;
  transition: all 0.4s ease;
  color: #413d37;
  font-weight: 700;
}

.faqs .faq .faq-question span {
  margin-right: 30px;
}

.faqs .faq .faq-question::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  width: 14px;
  height: 8px;

  background-image: url("./../../../../../../assets/images/arrow-down.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.faqs .faq .faq-answer {
  opacity: 0;
  padding: 0 25px;
  max-height: 0;
  overflow-y: hidden;
  color: #655f56;
  font-family: "Open Sans";
  font-size: 14px;
  line-height: 21px;
}

.faqs .faq.open {
  height: fit-content !important;
}

.faqs .faq .faq-question :first-child {
  display: flex;
}

.faqs .faq.open .faq-question {
  margin-bottom: 15px;
}

.faqs .faq.open .faq-question::after {
  transform: translateY(-50%) rotate(180deg);
}

.faqs .faq.open .faq-answer {
  max-height: 1000px;
  opacity: 1;
}

@media (max-width: 1024px) {
  .faqs .faq {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .faqs .faq .faq-question :first-child {
    max-width: 400px;
    display: flex;
  }
}

@media (max-width: 520px) {
  .faqs .faq .faq-question :first-child {
    max-width: 300px;
  }

  .faqs .faq {
    height: fit-content !important;
  }
}

@media (max-width: 430px) {
  .faqs .faq .faq-question :first-child {
    max-width: 180px;
    display: flex;
  }
}
