@import "../../App/variables.scss";

.SectionHeadline {
  font-size: 54px;
  text-align: center;
  font-family: $h_font_family;
  font-weight: bold;

  &.x_small {
    font-size: 24px;
  }

  &.small {
    font-size: 36px;
  }

  &.dark {
    color: $dark_brown;
  }

  &.darker {
    color: $brown;
  }

  &.light {
    color: $white;
  }

  &.left {
    text-align: left;
  }

  &.right {
    text-align: right;
  }
}
