.basic_btn {
  font-family: "Cabin", sans-serif;
  font-size: 18px;
  max-width: 160px;
  width: 100%;
  padding: 10px 10px 9px 10px;
  border-radius: 22.5px;
  font-weight: 500;
  display: inline-block;
  border: none;
  outline: none !important;
  box-shadow: none;
  cursor: pointer;
}
.basic_btn:focus {
  outline: none;
}
.basic_btn.fullwidth {
  max-width: 300px;
  margin-top: 15px;
}
.basic_btn.orange {
  color: #fff;
  background-image: linear-gradient(to top, #ff7554, #ffb070);
}
.basic_btn.orange-dark {
  color: #fff;
  background-color: #ff815a;
}
.basic_btn.white {
  color: #413d37;
  background-color: #fff;
}
.basic_btn.black {
  color: #fff;
  background-color: #413d37;
}
.basic_btn.bordered {
  background: none;
  max-width: 200px;
  padding: 6px 10px 6px 10px;
}
.basic_btn.bordered.is-small {
  max-width: 156px;
}
.basic_btn.bordered.light {
  border: solid 3px #fff;
  color: #fff;
}
.basic_btn.bordered.light.thin-border {
  border: solid 1px #fff;
}
.basic_btn.bordered.dark {
  border: solid 3px #413d37;
  color: #413d37;
}
.basic_btn.bordered.dark.thin-border {
  border: solid 1px rgba(65, 61, 55, 0.31);
}
.basic_btn.is-bigger {
  padding: 0px auto;
  border-radius: 28px;
  height: 56px;
}
.basic_btn.is-wide {
  max-width: fit-content;
  padding: 0px 40.5px;
}
.basic_btn.is-wider {
  max-width: fit-content;
  padding: 0px 60px;
}
.basic_btn.navigation {
  height: 56px;
  width: 176px;
  border-radius: 28px;
}
.basic_btn.full-width {
  width: 100%;
  max-width: 500px;
}
.basic_btn.btn-questionnaire {
  margin-top: 10px !important;
}
.basic_btn.btn-questionnaire.display-none {
  display: none;
}
.basic_btn.mobile-menu {
  margin-bottom: 0px !important;
}