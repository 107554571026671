.Team {
  padding-bottom: 300px;
  position: relative;
}
.Team p.full-width {
  max-width: fit-content;
}
.Team .cs_wave {
  width: 100%;
  position: absolute;
  bottom: -300px;
}

@media screen and (max-width: 1920px) {
  .Team .cs_wave {
    bottom: -180px;
  }
}
@media screen and (max-width: 1200px) {
  .Team {
    padding-bottom: 200px;
  }
  .Team .cs_wave {
    bottom: -150px;
  }
}
@media screen and (max-width: 992px) {
  .Team {
    padding-bottom: 150px;
  }
  .Team .cs_wave {
    bottom: -100px;
  }
}
@media screen and (max-width: 768px) {
  .Team .cs_wave {
    bottom: -50px;
  }
}