.NewsItem {
  background: #fff;
  border-radius: 6px;
}
.NewsItem .cs_imgwrap {
  height: 177px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  overflow: hidden;
  text-align: center;
}
.NewsItem .cs_imgwrap img {
  object-fit: cover;
  min-height: 100%;
  width: 100%;
}
.NewsItem .cs_contentwrap {
  padding: 26px 30px 30px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 195px;
}
.NewsItem .cs_contentwrap .cs_title {
  font-family: "Cabin", sans-serif;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #413d37;
  margin-bottom: 2px;
}
.NewsItem .cs_contentwrap .cs_text {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #655f56;
  margin-bottom: 10px;
}
.NewsItem .cs_contentwrap .cs_date {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #655f56;
}