@import "../../../../App/variables.scss";

.Solution {
  padding-top: 120px;
  height: auto;
  padding-bottom: 260px;
  position: relative;

  p {
    &.full-width {
      max-width: 920px;
    }
  }

  .toggle-buttons {
    text-align: center;
    margin: 60px auto 0px auto;
    background-color: $light_brown;
    width: fit-content;
    display: flex;
    justify-content: center;
    height: fit-content;
    border-radius: 27.5px;

    button {
      height: 56px;
      width: 270px;
      border-radius: 27.5px;
      font-size: 18px;
      font-weight: bold;
      background-color: transparent;
      color: $dark_brown;
      border: none;
    }

    .is-active {
      background-color: #ff8b5f;
      color: $white;
    }
  }

  .img-right {
    height: 620px;
    margin-bottom: 95px;
  }

  .accordion {
    margin: 140px 0 auto 0;
  }

  .cs_wave {
    width: 100%;
    position: absolute;
    max-height: 100%;
  }
}

@media screen and (max-width: $break-large) {
  .Solution {
    .img-right {
      width: 100%;
      height: auto !important;
      margin-bottom: 40px;
    }
  }
}

@media screen and (max-width: $break-small) {
  .Solution {
    .img-right {
      max-height: 475px !important;
    }
    .toggle-buttons {
      width: auto;
      margin-bottom: 50px;
    }
    .accordion {
      margin: auto 0;
      padding-bottom: 0px;
    }
  }
}

@media screen and (max-width: 420px) {
  .Solution {
    .img-right {
      max-height: 320px !important;
    }
  }
}
